import React from "react"
import clsx from "clsx"

import * as styles from "./formControl.module.scss"

type Props = {
  className?: string
  children: React.ReactNode
}

export default function FormControl({ className, children, ...props }: Props) {
  return (
    <div {...props} className={clsx(styles.formControl, className)}>
      {children}
    </div>
  )
}
