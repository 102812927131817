import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import TitleCard from "../TitleCard"
import { WalletIcon } from "../icons"
import * as styles from "../steps.module.scss"

export default function SecondStep() {
  return (
    <Container>
      <TitleCard
        img={WalletIcon}
        backgroundColor="#00AEFF"
        title={<>2 ШАГ: Деньги на карту</>}
        desc="Деньги ТОЛЬКО для безналичного расходования!"
      />

      <div className={styles.text_container}>
        <table className={styles.table}>
          <caption>
            Подробнее об условиях кредитования уточняйте у сотрудников в офисах ПАО «Совкомбанк».
          </caption>
          <thead>
            <th>Продукт</th>
            <th>
              Деньги на карту <br /> Вариант 1*
            </th>
            <th>
              Деньги на карту <br /> Вариант 2*
            </th>
            <th>
              Деньги на карту <br /> Вариант 3*
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Срок кредитования</td>
              <td colSpan={3} className={clsx(styles.red, styles.bold, styles.center, styles.big)}>
                6 мес.
              </td>
            </tr>
            <tr>
              <td>Сумма кредита</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>10 000 руб.</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>20 000 руб.</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>30 000 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  )
}
