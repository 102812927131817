import { decode } from "html-entities"

import { PageData } from "../interfaces/pageProps"

export function getPageData({ pageDataJson, admin }: PageData) {
  const { title, description } = pageDataJson || {}

  const ligal = admin?.page?.ligal?.text
  const phones =
    Array.isArray(admin?.page?.phones) && admin?.page?.phones?.length > 0 ? admin?.page?.phones : []

  const isHiddenPage = admin?.page?.notIndex

  const result = {
    // чтобы сработало значение по-умолчанию нужен undefined вместо null
    ligal: ligal ? decode(ligal) : undefined,
    phones: phones || undefined,
    title: title || undefined,
    description: description || undefined,
    isHiddenPage,
  }

  return result
}
