import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import type { PersonalInfoFormData, PersonalInfoFormDataFormatted, FieldsRecord } from "./types"

export const DEFAULT_LABELS: FieldsRecord<string> = {
  fio: "Фамилия Имя Отчество",
  birthDate: "Дата рождения",
  phone: "Телефон",
  region: "Регион",
  localityAddress: "Населенный пункт",
  email: "Email",
}

export const DEFAULT_FIELDS: FieldsRecord<boolean> = {
  fio: true,
  birthDate: true,
  phone: true,
  region: true,
  localityAddress: true,
  email: true,
}

export const FIELDS: FieldsRecord<boolean> = {
  fio: true,
  birthDate: true,
  phone: true,
  region: false,
  localityAddress: false,
  email: false,
}

const fieldsType = {
  fio: "fio",
  birthDate: "date",
  region: "region",
  localityAddress: "dadataAddress",
  email: "email",
}

export function formatValuesToRequest(values: PersonalInfoFormData): PersonalInfoFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)
  const { surname, name, patronymic } = valuesToRequest.fio

  valuesToRequest.additionalData = getAdditionalData()
  delete valuesToRequest.fio

  return { productName: "CreditDoctor", surname, name, patronymic, ...valuesToRequest }
}
