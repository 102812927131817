import React from "react"

import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import type { GatsbyImageProps, ImageDataLike } from "gatsby-plugin-image"

export interface BgImgProps extends Omit<GatsbyImageProps, "image"> {
  image: {
    mob: ImageDataLike
    tab: ImageDataLike
    desk: ImageDataLike
    large: ImageDataLike
  }
}

export default function BgImg({ image, ...restProps }: BgImgProps) {
  const images = withArtDirection(getImage(image.large)!, [
    {
      media: "(max-width: 599.95px)",
      image: getImage(image.mob)!,
    },
    {
      media: "(max-width: 959.95px)",
      image: getImage(image.tab)!,
    },
    {
      media: "(max-width: 1279.95px)",
      image: getImage(image.desk)!,
    },
  ])

  return <GatsbyImage {...restProps} image={images} />
}
