import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import TitleCard from "../TitleCard"
import { CrossIcon } from "../icons"

import * as stepsStyles from "../steps.module.scss"
import * as styles from "./firstStep.module.scss"

export default function FirstStep() {
  return (
    <Container>
      <TitleCard img={CrossIcon} title={<>1 ШАГ: Старт программы Улучшения Кредитной Истории</>} />
      <div className={stepsStyles.text_container}>
        <p className={styles.text}>
          Программа Улучшения Кредитной Истории (УКИ) – это специальное предложение по
          восстановлению кредитной истории.
          <br /> <br />
          Соблюдая правила пользования программой, в итоге вы сможете подать заявку на кредит на
          крупную сумму.
        </p>
        <h2 className={styles.tableHead}>Оформить программу УКИ</h2>
        <table className={stepsStyles.table}>
          <caption>
            Консультация по состоянию кредитной истории и рекомендации по ее улучшению от
            специалистов в офисе Банка - БЕСПЛАТНО!
          </caption>
          <thead>
            <th>Кредитный продукт</th>
            <th>
              Старт программы УКИ <br /> Вариант 1*
            </th>
            <th>
              Старт программы УКИ <br /> Вариант 2*
            </th>
            <th>
              Старт программы УКИ <br /> Вариант 3*
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Сумма кредита</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                4 999 руб.
              </td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                9 999 руб.
              </td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                14 999 руб.
              </td>
            </tr>
            <tr>
              <td>Срок кредитования</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>3 мес.</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                3 или 6 мес.
              </td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                3 или 6 мес.
              </td>
            </tr>
            {/* <tr>
              <td>Годовая ставка,&nbsp;%</td>
              <td
                colSpan={2}
                className={clsx(
                  stepsStyles.red,
                  stepsStyles.bold,
                  stepsStyles.center,
                  stepsStyles.big
                )}
              >
                39,9 %
              </td>
            </tr> */}
            {/* <tr>
              <td>Что включает продукт?</td>
              <td>
                Карта MC GOLD / WORLD «Золотой ключ Кредитный доктор» («
                <span className={stepsStyles.bold}>Тарифы по карте</span>») Страхование от НС на 50
                000 рублей
              </td>
              <td>
                Карта MC GOLD / WORLD «Золотой ключ Кредитный доктор» («
                <span className={stepsStyles.bold}>Тарифы по карте</span>») с ЗАЩИТОЙ ДОМА_КЛАССИКА
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </Container>
  )
}
