import React, { FC } from "react"

type Props = { className: string }

const Doc: FC<Props> = ({ className }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M51.7833 8.14993H25.1167V19.8166C25.1167 20.2586 24.9411 20.6825 24.6285 20.9951C24.3159 21.3077 23.892 21.4833 23.45 21.4833H11.7833V59.8166C11.7833 60.7006 12.1345 61.5485 12.7596 62.1736C13.3848 62.7987 14.2326 63.1499 15.1167 63.1499H38.7833C38.289 60.7281 38.3395 58.2267 38.9312 55.8269C39.5229 53.427 40.6409 51.1888 42.2044 49.2744C43.7678 47.3599 45.7375 45.8172 47.9708 44.7579C50.204 43.6987 52.6449 43.1494 55.1167 43.1499V11.4833C55.1167 10.5992 54.7655 9.75136 54.1403 9.12624C53.5152 8.50112 52.6674 8.14993 51.7833 8.14993ZM35.1167 48.1499H21.7833C21.3413 48.1499 20.9174 47.9743 20.6048 47.6618C20.2923 47.3492 20.1167 46.9253 20.1167 46.4833C20.1167 46.0412 20.2923 45.6173 20.6048 45.3048C20.9174 44.9922 21.3413 44.8166 21.7833 44.8166H35.1167C35.5587 44.8166 35.9826 44.9922 36.2952 45.3048C36.6077 45.6173 36.7833 46.0412 36.7833 46.4833C36.7833 46.9253 36.6077 47.3492 36.2952 47.6618C35.9826 47.9743 35.5587 48.1499 35.1167 48.1499ZM45.1167 39.8166H21.7833C21.3413 39.8166 20.9174 39.641 20.6048 39.3284C20.2923 39.0159 20.1167 38.592 20.1167 38.1499C20.1167 37.7079 20.2923 37.284 20.6048 36.9714C20.9174 36.6589 21.3413 36.4833 21.7833 36.4833H45.1167C45.5587 36.4833 45.9826 36.6589 46.2952 36.9714C46.6077 37.284 46.7833 37.7079 46.7833 38.1499C46.7833 38.592 46.6077 39.0159 46.2952 39.3284C45.9826 39.641 45.5587 39.8166 45.1167 39.8166ZM45.1167 31.4833H21.7833C21.3413 31.4833 20.9174 31.3077 20.6048 30.9951C20.2923 30.6825 20.1167 30.2586 20.1167 29.8166C20.1167 29.3746 20.2923 28.9506 20.6048 28.6381C20.9174 28.3255 21.3413 28.1499 21.7833 28.1499H45.1167C45.5587 28.1499 45.9826 28.3255 46.2952 28.6381C46.6077 28.9506 46.7833 29.3746 46.7833 29.8166C46.7833 30.2586 46.6077 30.6825 46.2952 30.9951C45.9826 31.3077 45.5587 31.4833 45.1167 31.4833Z"
      fill="#003791"
    />
    <path
      d="M55.1167 46.5166C52.4796 46.5166 49.9017 47.2986 47.7091 48.7637C45.5164 50.2288 43.8074 52.3111 42.7983 54.7475C41.7891 57.1838 41.5251 59.8647 42.0395 62.4511C42.554 65.0376 43.8239 67.4133 45.6886 69.278C47.5533 71.1427 49.929 72.4126 52.5155 72.9271C55.1019 73.4416 57.7828 73.1775 60.2191 72.1683C62.6555 71.1592 64.7378 69.4502 66.2029 67.2575C67.668 65.0649 68.45 62.487 68.45 59.8499C68.45 56.3137 67.0452 52.9223 64.5448 50.4218C62.0443 47.9214 58.6529 46.5166 55.1167 46.5166ZM61.5167 57.0666L55.7 65.6333L55.1167 66.5499L49.25 62.7333C49.0654 62.6135 48.9064 62.4584 48.7821 62.2769C48.6578 62.0953 48.5707 61.891 48.5258 61.6756C48.4808 61.4602 48.479 61.2381 48.5203 61.022C48.5617 60.8059 48.6454 60.6002 48.7667 60.4166L48.9 60.2166C49.0198 60.0321 49.1749 59.8731 49.3564 59.7488C49.5379 59.6245 49.7423 59.5373 49.9577 59.4924C50.173 59.4475 50.3952 59.4456 50.6112 59.487C50.8273 59.5283 51.0331 59.612 51.2167 59.7333L54.0833 61.6666L58.5333 54.9999C58.7803 54.6373 59.1603 54.3867 59.5909 54.3024C60.0215 54.2182 60.4679 54.3071 60.8333 54.5499L61.0667 54.6999C61.2556 54.8224 61.4177 54.9819 61.5433 55.1687C61.6689 55.3556 61.7553 55.5659 61.7974 55.7871C61.8394 56.0083 61.8362 56.2357 61.788 56.4556C61.7397 56.6755 61.6474 56.8834 61.5167 57.0666Z"
      fill="#FF4B5F"
    />
    <path
      d="M13.0167 18.1665H19.35C19.9912 18.1622 20.6051 17.9067 21.06 17.4549C21.5149 17.0031 21.7746 16.391 21.7833 15.7499V9.39986C21.7812 9.1575 21.7077 8.92114 21.5721 8.7203C21.4364 8.51946 21.2446 8.36304 21.0205 8.27058C20.7965 8.17812 20.5502 8.15372 20.3124 8.20042C20.0745 8.24713 19.8558 8.36287 19.6833 8.53319L12.15 16.0832C11.9772 16.2534 11.8593 16.4713 11.8113 16.709C11.7632 16.9467 11.7874 17.1933 11.8805 17.4172C11.9736 17.6411 12.1316 17.8321 12.334 17.9656C12.5364 18.0991 12.7742 18.1691 13.0167 18.1665Z"
      fill="#FF4B5F"
    />
  </svg>
)

export default Doc
