import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"

import type { DadataAddress } from "../interfaces/dadata"

export function noValidate() {
  return ""
}

export function validateDadataAddressWithoutHouse(dadataAddress: DadataAddress) {
  const { dadataValue } = dadataAddress

  if (requiredValidator(dadataValue)) {
    return "Выберите значение из списка"
  }

  if (!dadataValue || !dadataValue.data) {
    return "Выберите значение из списка"
  }

  const {
    data: { city, settlement },
  } = dadataValue

  if (!(city || settlement)) {
    return "Укажите город или населенный пункт"
  }

  return null
}

export function comparePhoneNumbers(phone1: string) {
  return (phone2: string) => {
    const reqMsg = requiredValidator(phone2)
    if (reqMsg) {
      return reqMsg
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (phone1 && phone1.slice(1) === phone2.slice(1)) {
      return "Номер телефона должен отличаться от введённого ранее"
    }

    return null
  }
}
