import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"
import Doc from "./img/doc"
import * as styles from "./advantages.module.scss"

export type AdvantagesItem = {
  count: React.ReactNode
  sup: string
  text1: string
  text2: ReactNode | string
  hint?: ReactNode
}

export default function Advantages() {
  return (
    <Container className={styles.root}>
      <div className={styles.container}>
        <Doc className={styles.icon} />
        <span className={styles.text}>
          Не откладывай на завтра то, что можно сделать сейчас! <br />
          Улучшай свою кредитную историю
        </span>
      </div>
    </Container>
  )
}
