import React from "react"
import { graphql, PageProps } from "gatsby"
import { PageData } from "../interfaces/pageProps"
import Layout from "../components/Layouts/base"
import { NoIndex } from "../components/NoIndex"
import Header from "../components/Header/HeaderDoktor"
import Banner from "../components/Banners/Doktor"
import Advantages from "../components/Advantages"
import { PersonalInfoLongForm } from "../components/PersonalInfoForm/LongApp"
import StepsTitle from "../components/Steps/StepsTitle"
import FirstStep from "../components/Steps/FirstStep"
import SecondStep from "../components/Steps/SecondStep"
import ThirdStep from "../components/Steps/ThirdStep"
import FourthStep from "../components/Steps/FourthStep"
import Footer from "../components/Footer"

import { getPageData } from "../helpers/getPageData"
import { FIELDS } from "../components/PersonalInfoForm/helpers"

const CreditDoktorPage = ({ data }: PageProps<PageData>) => {
  const { ligal, phones, isHiddenPage } = getPageData(data)
  return (
    <Layout>
      {isHiddenPage && <NoIndex />}
      <Header phones={phones} />
      <Banner />
      <div className="white_gradient">
        <Advantages />
        <PersonalInfoLongForm fields={FIELDS} />
        <StepsTitle />
        <FirstStep />
        <SecondStep />
        <ThirdStep />
        <FourthStep />
      </div>
      <Footer shortText={ligal} phones={phones} />
    </Layout>
  )
}
export default CreditDoktorPage

export const query = graphql`
  query {
    admin {
      page(url: "https://i.doktor.kr/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
