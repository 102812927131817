import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import scrollToForm from "../../../helpers/scrollToForm"

import BgImg, { BgImgProps } from "./BgImg"

import * as styles from "./BannerContainer.module.scss"

type Props = {
  imgData: BgImgProps["image"]
  offer: React.ReactNode
  subTitle?: React.ReactNode
}

export default function BannerContainer({ imgData, offer, subTitle }: Props) {
  return (
    <section className={styles.section}>
      <BgImg alt="bg image" className={styles.img} image={imgData} />
      <Container className={styles.container}>
        <div className={styles.text}>
          <h1 className={styles.title}>{offer}</h1>
          {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
        </div>

        <Button className={styles.btn} color="secondary" onClick={scrollToForm}>
          Улучшить историю
        </Button>
      </Container>
    </section>
  )
}
