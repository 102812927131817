import React from "react"
import * as styles from "./titleCard.module.scss"

type Props = {
  backgroundColor?: "#FF4B5F" | "#00AEFF" | "#2F4C87" | "#47C249"
  title?: React.ReactNode
  desc?: string
  img: React.FC<{ className?: string }>
}

export default function TitleCard({ img: Img, title, desc, backgroundColor = "#FF4B5F" }: Props) {
  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <Img className={styles.icon} />
      {title && <h1 className={styles.title}>{title}</h1>}
      {desc && <p className={styles.desc}>{desc}</p>}
    </div>
  )
}
