import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"

import TitleCard from "../TitleCard"
import { CheckIcon } from "../icons"

import * as stepsStyles from "../steps.module.scss"
import * as styles from "./FourthStep.module.scss"

export default function FourthStep() {
  return (
    <Container>
      <TitleCard
        img={CheckIcon}
        backgroundColor="#47C249"
        title={<> 4 ШАГ: Завершение программы улучшения кредитной истории</>}
      />
      <div className={stepsStyles.text_container}>
        <div className={styles.titleContainerFinal}>
          <h3 className={clsx(styles.final, styles.titleInFinalContainer)}>
            Поздравляем! Ваша кредитная история восстановлена.
            <br /> Вы можете подать заявку на денежный кредит на крупную сумму.
          </h3>
        </div>
        <p className={styles.muted_text}>
          Более подробную информацию и правила пользования продуктами по программе «Улучшение
          кредитной истории» рекомендуем уточнить в любом удобном отделении ПАО «Совкомбанк» <br />*
          В зависимости от выбранной программы после улучшения кредитной истории Вам доступны
          различные лимиты кредитования
        </p>
        <p className={styles.text}>
          Банк оставляет за собой право отказать в получении кредита по причинам не связанным с
          кредитной историей
        </p>
        <h3 className={styles.final}>Улучшайте кредитную историю в Совкомбанке!</h3>
      </div>
    </Container>
  )
}
