export default function disableWebvisor(node: HTMLElement) {
  if (node && node.parentElement) {
    const parent = node.parentElement
    const inputs = Array.from(parent.querySelectorAll("input"))

    inputs.forEach((input) => {
      if (!input.classList.contains("ym-disable-keys")) {
        input.classList.add("ym-disable-keys")
      }
    })
  }
}
