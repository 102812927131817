import React from "react"
import sendRequest from "@ecom/ui/utils/sendRequest"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { navigate } from "gatsby-link"

import { PersonalInfoForm, PersonalInfoFormProps } from "./Base"
import { setIDBValue } from "../../utils/idbUtils"

interface Response {
  requestId: string
  status: "pending" | "repeated" | "approve" | "reject" | "error"
  partnerExternalId: number
}

type Props = Omit<PersonalInfoFormProps, "onSubmit">

export function PersonalInfoLongForm(props: Props) {
  function handleSubmit(valuesToRequest: Record<string, any>) {
    sendRequest(`${process.env.GATSBY_APP_API_URL}/v2/app/public/start`, {
      ...valuesToRequest,
      phoneValidate: true,
      save: true,
    })
      .then((res: Response) => {
        if (["pending", "approve"].includes(res.status)) {
          setIDBValue("apiId", res.requestId)
          return Promise.resolve(res.status)
        }

        return Promise.reject(new Error(res.status))
      })

      .then(() => {
        pushToDataLayer({
          event: "applicationSend",
          result: "success",
        })

        return navigate("/verify")
      })
      .catch(() => {
        pushToDataLayer({
          event: "applicationSend",
          result: "error",
        })

        return navigate("/error")
      })
  }

  return <PersonalInfoForm {...props} onSubmit={handleSubmit} name="longPersonalForm" />
}
