import React, { useState, useRef, useCallback } from "react"

import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import DadataAddress from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddress"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import AutocompleteRegionByPhoneField from "@ecom/ui/components/FormLanding/fields/AutocompleteRegionByPhoneField"
import PhoneFieldWithSeven from "@ecom/ui/components/FormLanding/fields/PhoneFieldWithSeven"
import AcceptmentField from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import EmailField from "@ecom/ui/components/FormLanding/fields/EmailField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import getKladrIds from "@ecom/ui/helpers/getKladrIds"

import FormControl from "../FormControl"

import { DEFAULT_LABELS, DEFAULT_FIELDS, formatValuesToRequest } from "./helpers"
import { setIDBValue } from "../../utils/idbUtils"
import { validateDadataAddressWithoutHouse } from "../../helpers/validators"
import disableWebvisor from "../../utils/disableWebvisor"

import type { FieldData } from "../../interfaces/fieldData"
import type { RegionData, PersonalInfoFormData } from "./types"

import * as styles from "./pif.module.scss"

const LOCALITY_DADATA_OPTIONS = {
  count: 10,
  from_bound: { value: "city" },
  restrict_value: true,
  to_bound: { value: "settlement" },
}
const EMPTY_REGION_VALUE = { label: "", value: "", kladr_id: "" }

export type PersonalInfoFormProps = {
  title?: React.ReactNode
  submitButtonText?: string
  onSubmit: (v: Record<string, any>) => void
  labels?: typeof DEFAULT_LABELS
  fieldsAB?: boolean
  name?: string
  fields?: typeof DEFAULT_FIELDS
}

export function PersonalInfoForm({
  title = "Заполните заявку",
  submitButtonText = "Отправить",
  onSubmit,
  fieldsAB = false,
  name,
  labels,
  fields,
}: PersonalInfoFormProps) {
  const fieldsLabel = { ...DEFAULT_LABELS, ...labels }
  const displayedFields = { ...DEFAULT_FIELDS, ...fields }
  const refForm = useRef<any>(null)
  const refPhone = useRef<HTMLInputElement>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [userPhone, setUserPhone] = useState<string>()
  const [region, setRegion] = useState<RegionData>(EMPTY_REGION_VALUE)
  const dadataOptions = {
    ...LOCALITY_DADATA_OPTIONS,
    locations: getKladrIds(region.kladr_id),
  }

  const onChangeFields = {
    birthDate: ({ value }: FieldData<string>) => {
      if (fieldsAB && value.length === 10) {
        refPhone?.current?.focus()
      }
    },
    phone: ({ value }: FieldData<string>) => {
      setUserPhone(value)
    },
    region: ({ value }: FieldData<RegionData>) => {
      if (!value) {
        refForm.current.setField("localityAddress", { value: "" })
        setRegion(EMPTY_REGION_VALUE)
        return
      }

      const newRegionValue = value
      const prevRegionValue = region

      setRegion(newRegionValue)
      if (!newRegionValue.value || newRegionValue.kladr_id !== prevRegionValue.kladr_id) {
        refForm.current.setField("localityAddress", { value: "" })
      }
    },
  }

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  function handleSubmit(values: PersonalInfoFormData) {
    setIsSubmitting(true)
    setIDBValue("phone", userPhone)
    const valuesToRequest = formatValuesToRequest(values)
    onSubmit(valuesToRequest)
  }

  return (
    <section id="app" className="content-visibility-auto">
      <Container className={styles.root}>
        {title && (
          <h2 className={styles.head} ref={ref}>
            {title}
          </h2>
        )}
        <FormLanding
          name={name}
          onChangeFields={onChangeFields}
          onSubmit={handleSubmit}
          ref={refForm}
        >
          <FormControl>
            <DadataFio label={fieldsLabel.fio} name="fio" />
          </FormControl>
          <FormControl>
            <DateField
              label={fieldsLabel.birthDate}
              name="birthDate"
              placeholder="дд.мм.гггг"
              max={new Date()}
              validAgeMin={18}
              validAgeMax={85}
            />
          </FormControl>
          <FormControl>
            <PhoneFieldWithSeven label={fieldsLabel.phone} name="phone" />
          </FormControl>
          {displayedFields.region && (
            <FormControl>
              <AutocompleteRegionByPhoneField
                label={fieldsLabel.region}
                name="region"
                phone={userPhone}
              />
            </FormControl>
          )}
          {displayedFields.localityAddress && region.value && (
            <FormControl>
              <DadataAddress
                label={fieldsLabel.localityAddress}
                name="localityAddress"
                dadataOptions={dadataOptions}
                validate={validateDadataAddressWithoutHouse}
              />
            </FormControl>
          )}
          {displayedFields.email && (
            <FormControl>
              <EmailField label={fieldsLabel.email} name="email" placeholder="example@example.ru" />
            </FormControl>
          )}
          <AcceptmentField name="agree" />
          <div className={styles.formBtnOuter}>
            <SubmitButton disabled={isSubmitting}>{submitButtonText}</SubmitButton>
          </div>
        </FormLanding>
      </Container>
    </section>
  )
}
